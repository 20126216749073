.page-half.is-left {
    @apply
        border-b-sm
        border-brand-primary
        bg-white;

    @screen lg {
        @apply border-0;
    }

    .logo-container {
        @apply rounded-full bg-blue-500;
    }

    .logo-container::after {
        background: transparent;
        border-radius: 50%;
        box-shadow: 0 24px 8px rgba(167, 167, 167, 0.8);
    }

    h3 {
        @apply font-semibold;
    }
}

.page-half.is-right {
    background-image: url('/img/bg-login.svg');
}

.page-half.is-right div {
    @apply bg-brand-primary;
}
