.alert {
    @apply
        w-full
        flex
        items-center
        relative
        px-xl
        py-xl
        mb-xl
        z-20;
}

.alert.is-flashy {
    @apply fixed w-auto pr-xl;

    bottom: 40px;
    right: 40px;
}

.alert svg.close {
    @apply ml-auto cursor-pointer;
}

.alert-icon {
    @apply
        flex
        items-center
        justify-center
        flex-none
        mr-lg;

    width: 40px;
    height: 40px;
}