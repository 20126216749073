.form-group {
    @apply relative w-full mb-lg;

    table & {
        @apply mb-0;
    }
}

.form-group:last-of-type {
    @apply mb-0;
}

.floating-label {
    pointer-events: none;
}

/* Floating labels, needs a form-group around the input and label */
.input {
    ~ .floating-label,
    &:focus ~ .floating-label {
        @apply absolute ml-lg top-0 left-0;

        margin-top: 8px;
        transition: 0.2s ease all;
    }
}

.input.textarea {
    ~ .floating-label,
    &:focus ~ .floating-label {
        @apply absolute ml-lg top-0 left-0;

        margin-top: 10px;
    }
}

//For floating labels to work in IE & Edge, the transition triggers needs to be put here
.form-group-floating-label > .input {
    padding-top: 15px;
}

.form-group-floating-label > .input.textarea {
    padding-top: 22px;
}

.input:not(:focus) {
    &.placeholder-shown,
    &:placeholder-shown {
        + .floating-label {
            @apply absolute top-0 left-0 ml-lg;

            margin-top: 17px;
        }
    }
}

.input[type=date]:not(:focus).placeholder-shown + .floating-label {
    @apply absolute top-0 left-0 ml-lg mt-sm;
}

.form-group-floating-label label {
    @apply select-none;
}
