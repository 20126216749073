/* stylelint-disable no-descending-specificity  */
// Disabled because of this bug: https://github.com/stylelint/stylelint/issues/2489

.button-group {
    @apply flex flex-col-reverse;

    @screen lg {
        @apply flex-row;
    }
}

.button-group > * {
    @apply w-full ml-0 mt-lg;

    @screen lg {
        @apply w-auto ml-sm mt-0;
    }
}

.button-group > *:first-child {
    @apply mr-auto ml-0;
}

.button-group *:last-child {
    @apply mt-0;
}

@screen md {
    .button-group-half {
        @apply w-2/3;
    }
}

// So you can have a div with a button in it, useful if you
// have a Vue component with a button and need to wrap it in a div
.button-group > * * {
    @apply w-full;
}

.card .button-group {
    @apply mt-xl;
}

@screen md {
    .card-half {
        .button-group {
            @apply flex-row;
        }

        .button-group > * {
            @apply mb-lg w-auto;
        }

        // So you can have a div with a button in it, useful if you
        // have a Vue component with a button and need to wrap it in a div
        .button-group > * * {
            @apply w-auto;
        }
    }

    .card .card-utilities.button-group {
        @apply mt-0;
    }
}
