.card {
    @apply
        py-xl
        px-lg
        w-full
        mb-2xl;
}

@screen md {
    .card {
        @apply px-xl;
    }
}

.card-header {
    @apply
        flex
        justify-between
        items-center
        mb-xl;
}

.card-title {
    @apply flex items-center;
}

.card-half {
    @apply w-full;
}

@screen md {
    .card-half {
        @apply w-1/2;
    }
}

.card-icon {
    @apply flex items-center justify-center mr-md w-3xl h-3xl;
}

.card.is-toggle-card .card-header {
    @apply mb-0;
}

.card-actions {
    @apply flex-none flex items-center;

    @screen md {
        @apply justify-end;
    }

    > * {
        @apply ml-lg;
    }

    > *:first-child {
        @apply ml-0;
    }
}

.card.is-toggle-card .card-content {
    @apply mt-lg;

    @screen md {
        @apply mt-xl;
    }
}

.card.is-toggle-card .card-title {
    @apply w-full;
}