.filter {
    @apply text-black bg-white;

    @screen md {
        @apply rounded-lg shadow;
    }
}

.filter .filter-top {
    @apply bg-white;
}

.filter .filter-bottom {
    @apply bg-white border-t border-gray-200;
}
