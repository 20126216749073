.page-half {
    @apply
        fixed
        top-0
        z-10
        overflow-x-hidden
        h-full
        w-full;

    @screen lg {
        @apply w-1/2;
    }
}

.page-half.is-left {
    @apply
        left-0
        flex
        items-center
        justify-center;

    @screen lg {
        @apply border-0;
    }

    .logo-container {
        @apply
            relative
            h-7xl
            w-7xl
            flex-center;
    }

    .logo-container::after {
        @apply
            absolute
            bottom-0
            block;

        content: '';
        width: 72px;
        height: 2px;
    }

    .logo {
        @apply h-3xl w-auto mb-sm;
    }

    h3 {
        @apply mb-xl;
    }
}

.page-half.is-right {
    @apply
        hidden
        right-0
        bg-cover;

    @screen lg {
        @apply block;
    }
}

.page-half.is-right div {
    @apply opacity-85 w-full h-full;
}
