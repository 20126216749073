input.is-search {
    @apply border-0 bg-white shadow text-md;

    @screen lg {
        @apply rounded-lg;
    }
}

input.is-search:focus {
    outline: none;
}
