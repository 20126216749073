.fa-blade-icon {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}

.fa-blade-icon.size-sm {
    width: 0.8em;
}

.fa-blade-icon.size-md {
    width: 1em;
}

.fa-blade-icon.size-lg {
    width: 1.2em;
}

.fa-blade-icon.size-xl {
    width: 1.4em;
}

.fa-blade-icon[spin] {
    animation-name: fa-icon-spin;
    animation-duration: 2000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes fa-icon-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
