.hamburger-btn::before,
.hamburger-btn::after {
    @apply bg-gray-600 rounded-full;
}

.hamburger-btn::before {
    box-shadow: 0 7px 0 theme('colors.gray.600');
}

.hamburger-btn.is-open {
    &::before {
        box-shadow: 0 0 0 theme('colors.gray.600');
    }
}
