nav.tab-bar {
    @apply flex;
}

nav.tab-bar-standard {
    &::after {
        @apply
            absolute
            inset-x-0
            bottom-0
            block;

        content: '';
    }

    a {
        @apply
            z-10
            block
            py-md
            px-0
            mr-xl
            flex-shrink-0;

        margin-top: 2px;

        &:first-of-type {
            @apply ml-0;
        }
    }
}

.card nav.tab-bar,
nav.tab-bar-card {
    &::after {
        @apply
            absolute
            bottom-0
            block
            w-full;

        content: '';
    }

    a {
        @apply
            z-10
            block
            pb-md
            px-0
            mr-xl
            flex-shrink-0;

        margin-top: 2px;

        &:first-of-type {
            @apply ml-0;
        }
    }
}

.tab-overflow-overlay {
    @apply
        absolute
        bottom-0
        right-0
        h-2xl
        w-3xl;

    @screen md {
        @apply  w-4xl;
    }
}
