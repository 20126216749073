[v-cloak],
[x-cloak] {
    display: none;
}

.page-centered {
    @screen md {
        @apply flex flex-col items-center;
    }
}

.outline-0 {
    outline: none !important;
}

.text-current {
    color: currentColor;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}
