.search-select input.has-icon-left {
    @apply pl-2xl text-md;

    height: 36px;

    @screen md {
        @apply text-sm;
    }
}

.search-select .placeholder {
    @apply text-gray-400;
}

.search-select .dropdown {
    @apply
        bg-white
        border
        border-gray-200
        rounded;
}

.search-select button.option:hover,
.search-select button.option:hover .icon {
    @apply bg-gray-100;
}
