// Simple css tooltips
// Use mainly for help text with a question mark icon
// If the tooltip is only for desktop (eg. a button that when clicked performs something)
// we should add the class .tooltip-desktop so that nothing will happen on mobile devices
[data-tooltip] {
    cursor: pointer;
    position: relative;
}
[data-tooltip]:before, [data-tooltip]:after {
    position: absolute;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.15s cubic-bezier(0.5, 1, 0.25, 1);
    z-index: 1;
}
[data-tooltip]:before {
    @apply p-sm bg-black text-white text-center;

    width: 110px;
    border-radius: 3px;
    content: attr(data-tooltip);
    font-family: -apple-system, BlinkMacSystemFont, Segoe UI;
    font-size: 12px;
    font-weight: 600;
}
[data-tooltip]:after {
    border: 8px solid transparent;
    width: 0;
    content: "";
    font-size: 0;
    line-height: 0;
}
[data-tooltip]:hover:before, [data-tooltip]:hover:after {
    visibility: visible;
    opacity: 1;
}
[data-tooltip].tooltip-xl:before {
    width: 200px;
}
[data-tooltip].tooltip-lg:before {
    width: 170px;
}
[data-tooltip].tooltip-md:before {
    width: 140px;
}
[data-tooltip].tooltip-sm:before {
    width: 110px;
}
[data-tooltip].tooltip-xs:before {
    width: 80px;
}
[data-tooltip].tooltip-top:before {
    bottom: 100%;
    left: 50%;
    margin-bottom: 5px;
    transform: translateX(-50%);
}
[data-tooltip].tooltip-top:after {
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-top: 8px solid #000;
    border-bottom: none;
}
[data-tooltip].tooltip-top:hover:before, [data-tooltip].tooltip-top:hover:after {
    transform: translateX(-50%) translateY(-5px);
}
[data-tooltip].tooltip-right:before {
    top: 50%;
    left: 100%;
    margin-left: 5px;
    transform: translateY(-50%);
}
[data-tooltip].tooltip-right:after {
    top: 50%;
    left: 100%;
    transform: translateY(-50%);
    border-right: 8px solid #000;
    border-left: none;
}
[data-tooltip].tooltip-right:hover:before, [data-tooltip].tooltip-right:hover:after {
    transform: translateX(5px) translateY(-50%);
}
[data-tooltip].tooltip-bottom:before {
    top: 100%;
    left: 50%;
    margin-top: 5px;
    transform: translateX(-50%);
}
[data-tooltip].tooltip-bottom:after {
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    border-bottom: 8px solid #000;
    border-top: none;
}
[data-tooltip].tooltip-bottom:hover:before, [data-tooltip].tooltip-bottom:hover:after {
    transform: translateX(-50%) translateY(5px);
}
[data-tooltip].tooltip-left:before {
    top: 50%;
    right: 100%;
    margin-right: 5px;
    transform: translateY(-50%);
}
[data-tooltip].tooltip-left:after {
    top: 50%;
    right: 100%;
    transform: translateY(-50%);
    border-left: 8px solid #000;
    border-right: none;
}
[data-tooltip].tooltip-left:hover:before, [data-tooltip].tooltip-left:hover:after {
    transform: translateX(-5px) translateY(-50%);
}

// Disable tooltips for mobile devices IF it has the class
// ".tooltip-desktop-only"
@media only screen and (hover: none) {
    [data-tooltip].tooltip-desktop-only:before, [data-tooltip].tooltip-desktop-only:after {
        display: none !important;
    }
}
