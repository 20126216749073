.badge {
    @apply
        flex
        justify-center
        items-center
        cursor-pointer;

    height: 20px;
    width: 20px;

    &.is-floating {
        @apply
            z-10
            absolute
            top-0
            right-0
            -mt-sm
            -mr-sm;
    }
}

.badge.is-pill {
    @apply px-sm;

    width: unset;
}
