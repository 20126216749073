.description-list {
    @apply table-fixed w-full;

    tr th,
    tr td {
        @apply
            align-top
            block
            py-md
            px-lg
            break-words
            text-left;

        @screen md {
            @apply
                table-cell
                h-3xl
                py-sm;
        }
    }

    tr > *:first-of-type {
        @apply pb-sm;
    }

    tr > *:last-of-type {
        @apply pt-0;

        @screen md {
            @apply pt-sm;
        }
    }
}
