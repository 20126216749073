.system-bar {
    @apply
        w-full
        h-system-bar;

    .impersonate-item {
        @apply
            relative
            text-right
            h-full
            items-center
            flex;

        button {
            @apply px-2xl;
        }
    }

    .item {
        @apply
            relative
            text-right
            h-full
            items-center
            flex;

        > button,
        a {
            @apply px-lg;

            @screen md {
                @apply px-xl;
            }
        }

        &:last-child > button {
            @apply pr-0;
        }
    }
}
