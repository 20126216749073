.sidebar {
    @apply
        bg-white
        text-white
        text-black
        duration-500
        transition-[transform];

    @screen lg {
        @apply transition-[width] duration-500;
    }
}

.sidebar .logo-wrapper {
    @apply bg-brand-primary rounded-lg;
}

.sidebar-link {
    @apply
        bg-white
        rounded-md
        text-gray-900
        text-sm
        font-normal;
}

.sidebar-link:hover,
.sidebar-link.is-active {
    @apply text-blue-500;
}

.sidebar-link.is-active {
    @apply bg-blue-100 font-bold;
}

.sidebar-link svg {
    @apply text-lg;
}

.sidebar-toggler {
    @apply text-gray-800;

    &:hover {
        @apply text-blue-500;
    }
}

// Desktop: Collapsible sidebar animation
@screen lg {
    .sidebar.is-expanded {
        @apply w-sidebar;

        .logo-wrapper {
            @apply h-6xl w-full px-lg;
        }
    }

    .sidebar.is-collapsed {
        @apply w-6xl p-sm;

        .logo-wrapper {
            @apply h-4xl w-auto px-sm;
        }
    }

    .sidebar .logo-wrapper {
        @apply transition-all duration-500;
    }

    .sidebar-link {
        @apply w-full;

        span {
            @apply ml-lg;
        }
    }

    .sidebar.is-expanded .sidebar-link span {
        @apply transition-[opacity] duration-100;
    }

    .sidebar.is-collapsed .sidebar-link {
        @apply w-4xl;
    }

    .sidebar.is-collapsed .sidebar-link span {
        @apply
            pointer-events-none
            transition
            duration-300
            absolute
            opacity-0
            text-blue-500;

        left: 56px;
    }

    .sidebar.is-collapsed .sidebar-link:hover span {
        @apply
            translate-x-sm
            opacity-100;
    }
}

// Sidebar transition for handhelds
.sidebar.is-closed {
    transform: translateX(-240px);

    @screen lg {
        transform: translateX(0);
    }
}

.sidebar.is-open  {
    transform: translateX(0);

    @screen lg {
        transform: translateX(0);
    }
}
