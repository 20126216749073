.check-multi-select .checklist {
    @apply overflow-y-auto;

    max-height: 240px;
}

.check-multi-select .form-group.is-search {
    @apply w-auto;

    .input {
        @apply h-3xl;
    }

    .input.has-icon-left {
        @apply pl-2xl;
    }

    .input-icon {
        @apply text-xs;
    }
}

.check-multi-select .is-open .select-dropdown-arrow {
    @apply transform rotate-180;
}

.check-multi-select .dropdown {
    @apply
        z-40
        absolute
        mt-sm
        w-full
        mb-md
        pt-md;
}
