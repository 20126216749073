.submenu {
    @apply
        mt-sm
        absolute
        select-none
        p-sm
        mt-lg
        right-0
        z-50;

    min-width: 200px;
    top: 100%;

    hr {
        @apply mx-xs;
    }

    .list {
        li {
            @apply text-left;
        }

        a,
        button,
        li button {
            @apply
                block
                p-sm
                mb-0
                w-full
                text-left
                items-start;
        }

        svg {
            @apply ml-sm mr-xs;
        }
    }
}

.submenu-above {
    top: auto;
    bottom: 110%;
}
