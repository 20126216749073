.user-bar {
    @apply
        flex
        items-end
        w-full
        h-user-bar;

    .item {
        @apply relative text-right;

        button {
            @apply
                p-sm
                px-2xl;
        }
    }
}

.user-bar .action-button {
    @apply h-4xl w-4xl flex-center;
}

.user-bar .action-button .badge.is-floating {
    @apply -mt-xs -mr-xs;

    &.is-pill {
        @apply -mr-sm;
    }
}

.user-bar .select .input{
    @apply h-4xl flex-none;

    width: 235px;
}
