.action-button {
    @apply
        cursor-pointer
        flex-none
        h-2xl
        w-2xl
        flex
        items-center
        justify-center;

    transition: ease 0.3s;
}
