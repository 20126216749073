nav.tab-bar-standard {
    &::after {
        @apply border-b-xs border-gray-200;
    }

    a {
        @apply border-b-xs border-gray-200 text-sm text-gray-900;

        &:hover {
            @apply border-blue-500;
        }

        &.is-active {
            @apply border-b-xs border-blue-500 font-bold;
        }
    }

    + .tab-overflow-overlay {
        background-image: linear-gradient(to right, rgba(243, 244, 245, 0), #F3F4F5);
    }
}

nav.tab-bar-card {
    &::after {
        @apply border-b-xs border-gray-200;
    }

    a {
        @apply text-sm text-gray-600;

        &:hover {
            @apply border-b-xs border-blue-500 text-blue-500;
        }

        &.is-active {
            @apply border-b-xs border-blue-500 font-bold text-blue-500;
        }
    }

    + .tab-overflow-overlay {
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), #FFFFFF);
    }
}

