.table-list {
    @apply
        mt-lg
        min-w-full
        text-left
        table-fixed;

    @screen md {
        @apply w-full;

        min-width: unset;
    }

    th {
        @apply pb-md px-lg;
    }

    th.number,
    td.number {
        @apply text-right;
    }

    td {
        @apply py-xs px-lg whitespace-nowrap;

        @screen md {
            @apply h-4xl whitespace-normal;
        }
    }

    tr td:first-of-type,
    tr th:first-of-type {
        @apply pl-0;
    }

    &.is-striped {
        tr td:first-of-type,
        tr th:first-of-type {
            @apply pl-xl;
        }
    }
}
