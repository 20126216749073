.container {
    @apply
        w-full
        mx-auto
        max-w-content
        px-lg;

    @screen md {
        @apply px-xl;
    }

    @screen xl {
        @apply px-2xl;
    }
}

.container-full {
    @apply w-full px-lg;

    @screen md {
        @apply px-xl;
    }

    @screen xl {
        @apply px-2xl;
    }
}

@screen 2xl {
    .container {
        max-width: 1744px;
    }

    .container-standalone {
        max-width: 1744px;
    }

    .has-sidebar .container {
        /** Minus sidebar width */
        max-width: calc(1744px - theme('width.sidebar'));
    }
}
