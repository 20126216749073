.user-bar {
    @apply bg-gray-100 text-sm;

    .item {
        @apply text-white;

        button {
            @apply no-underline text-white;
        }
    }
}

.user-bar .action-button {
    @apply
        rounded-xl
        bg-white
        text-gray-600
        shadow
        cursor-pointer
        transition
        duration-200;

    &:hover, &.is-active {
        @apply bg-gray-900 text-white;
    }
}

.user-bar .select .input {
    @apply border-none rounded-lg shadow;
}

.user-bar .select  .input ~ .floating-label {
    @apply text-gray-600;

    font-size: 10px;
}