.sidebar {
    @apply
        fixed
        z-30
        top-0
        left-0
        h-full
        w-sidebar
        overflow-y-auto
        p-lg
        whitespace-nowrap;

    // Minus mobile bottom bar height
    max-height: calc(100% - 68px);

    @screen md {
        width: unset;
    }

    @screen lg {
        @apply
            relative
            z-30
            top-0
            min-h-admin-screen
            flex
            flex-col;

        overflow-y: unset;
        max-height: unset;
    }
}

.sidebar-link {
    @apply
        h-4xl
        w-full
        px-lg
        flex
        items-center;

    &:last-of-type {
        @apply mb-lg;
    }

    svg {
        transition: all 120ms;
    }

    span {
        @apply ml-sm;
    }
}
