@tailwind base;

@charset "UTF-8";

@import url('https://fonts.googleapis.com/css?family=Nunito:400,600,800');
@import "../../vendor/adaptivemedia/atlantis-core/resources/sass/atlantis";
@import "../../vendor/adaptivemedia/atlantis-core/resources/sass/defaultTheme/defaultTheme";

@tailwind components;
@tailwind utilities;

@import "../../vendor/adaptivemedia/atlantis-core/resources/sass/utilities/utilities";
