.input,
.input-no-label {
    @apply
        appearance-none
        w-full
        px-lg
        pb-0;

    height: 56px;
}

.form-group.has-icon {
    @apply
        w-full
        flex
        items-center;

    &.form-group-floating-label .input {
        @apply pt-lg;
    }
}

.form-group.has-clear-button {
    @apply
        w-full
        flex
        items-center;

    input {
         @apply pr-2xl;
    }

    &.form-group-floating-label .input {
        @apply pt-lg;
    }
}

.input-icon {
    @apply flex-none absolute;

    transition: all 0.2s;
}

.input-icon.has-background {
    @apply
        h-2xl
        w-2xl
        flex
        justify-center
        items-center;
}

.input.has-icon-right,
.input-no-label.has-icon-right {
    @apply pr-3xl;

    ~ .input-icon {
        @apply right-0 mr-lg;
    }
}

.input.has-icon-left,
.input-no-label.has-icon-left {
    @apply pl-3xl;

    ~ .input-icon {
        @apply ml-lg;
    }

    ~ label {
        @apply pl-xl;
    }
}
