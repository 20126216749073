.page-header {
    @apply flex flex-col;

    @screen md {
        @apply flex-row items-start;
    }
}

.page-header .page-actions {
    @apply flex items-center;

    @screen md {
        @apply ml-auto;
    }

    > * {
        @apply ml-lg;

        &:first-child {
            @apply ml-0;
        }
    }
}

.page-header .page-actions .btn {
    @apply w-full;

    @screen md {
        @apply w-auto;
    }
}

.page-header .action-button {
    @apply
        h-auto
        w-auto
        py-md
        px-xs
        relative
        cursor-pointer;
}

.page-header .action-button .text {
    @apply ml-sm inline-block;
}
