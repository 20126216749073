@screen lg {
    .search-results {
        @apply rounded shadow;
    }
}

.super-searcher {
    abbr[title] {
        text-decoration: none;
    }
}

.search-results .result-item {
    @apply bg-white border-b border-gray-100;

    &:first-child {
        @apply rounded-t;
    }

    &:last-child {
        @apply rounded-b;
    }

    &:hover,
    &.is-selected {
        @apply bg-blue-500;

        a {
            @apply text-white;
        }
    }

    a {
        @apply text-gray-900;
    }

    .title {
        @apply text-sm font-semibold;
    }

    .subtitle {
        @apply text-xs;
    }

    .category {
        @apply text-xs;
    }
}

.search-results .no-results {
    @apply rounded bg-white;
}

.all-search-results .result-item {
    @apply bg-gray-100 text-gray-600 rounded;

    .title {
        @apply text-gray-900 font-semibold;
    }

    .subtitle {
        @apply text-gray-600;
    }

    .type {
        @apply text-gray-600;
    }
}

.all-search-results .result-item:hover {
    .title {
        @apply text-blue-500;
    }
}
