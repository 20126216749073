a {
    @apply cursor-pointer;

    transition: all 0.2s;

    &.disabled {
        cursor: not-allowed;
        pointer-events: none;
        opacity: 0.7;
    }
}
