.filter {
    @apply
        flex
        flex-col
        overflow-y-auto
        h-full
        w-screen
        min-w-full;

    @screen md {
        @apply w-filter;

        max-height: 584px;
    }
}

.filter-top,
.filter-bottom {
    @apply p-lg;

    @screen md {
        @apply p-xl;
    }
}

.filter-bottom {
    @apply mt-auto;

    @screen md {
        @apply mt-xl;
    }
}

.filter-options {
    @apply px-lg;

    @screen md {
        @apply px-xl;
    }
}
