.page-header .action-button {
    @apply
        text-md
        text-gray-900
        transition
        duration-200;

    @screen lg {
        &:hover {
            @apply text-brand-primary;
        }
    }
}

.page-header .is-active .action-button,
.page-header .action-button.is-active {
    @apply text-brand-primary;
}
