input,
text,
textarea,
select,
button,
a {
    &:focus {
        @apply outline-none;
    }

    &:focus-visible {
        @apply ring-1 ring-brand-primary;
    }
}

input,
textarea {
    @apply text-md;

    @screen md {
        @apply text-sm;
    }
}

input[readonly],
textarea[readonly] {
    @apply pointer-events-none bg-gray-100;
}

.input,
.input-no-label {
    @apply
        border
        border-gray-200
        rounded
        leading-input
        transition-colors
        ease-in-out
        duration-300;
}

.form-group.has-icon {
    @apply text-md;

    @screen md {
        @apply text-sm;
    }
}

.form-group.has-clear-button .clear-button {
    @apply text-gray-600;
}

.input-icon {
    @apply text-gray-400;
}

.input-icon.has-background {
    @apply
        bg-gray-100
        rounded-full
        text-sm
        text-gray-900;
}

.input.has-icon-right,
.input-no-label.has-icon-right,
.input.has-icon-left,
.input-no-label.has-icon-left {
    &:focus {
        + .input-icon {
            @apply text-blue-500;
        }

        + .input-icon.has-background {
            @apply bg-blue-500 text-white;
        }
    }
}
