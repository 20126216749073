.btn {
    @apply
        rounded-lg
        font-semibold
        transition-colors
        ease-in-out
        duration-300;
}

.btn.is-primary {
    @apply bg-blue-500 text-white;

    &:hover {
        @apply bg-blue-400;
    }
}

.btn.is-primary.is-danger {
    @apply text-white bg-danger-500;

    &:hover {
        @apply bg-danger-400;
    }
}

.btn.is-secondary {
    @apply border border-blue-500 text-blue-500;

    &:hover {
        @apply border-blue-400 text-blue-400;
    }
}

.btn.is-secondary.is-neutral {
    @apply bg-transparent border border-gray-900 text-gray-900;

    &:hover {
        @apply bg-transparent border-gray-400 text-gray-400;
    }
}

.btn.is-secondary.is-inverted {
    @apply border-white text-white;

    &:hover {
        @apply opacity-75;
    }
}

.btn.is-secondary.is-danger {
    @apply border-danger-500 text-danger-500;

    &:hover {
        @apply border-danger-400 text-danger-400;
    }
}


.btn.is-ghost-link {
    @apply text-gray-900;
}

.btn-sm {
    @apply text-sm;
}

.btn-md {
    @apply text-sm;
}

.btn-lg {
    @apply text-md;
}

// Loader used in Form Loader
.btn.is-loading {
    @apply bg-gray-400 border-gray-600;

    &::after {
        border: 2px solid rgba(255, 255, 255, 0.5);
        border-left-color: #fff;
        border-top-color: #fff;
    }
}

.btn.has-only-icon {
    @apply rounded-full;
}

.btn-link {
    @apply text-brand-primary;
}

.btn-text {
    @apply text-gray-900;
}

.btn-link,
.btn-text {
    @apply
        text-sm
        font-normal
        text-left;

    &:hover {
        @apply no-underline;
    }

    &:focus {
        @apply outline-none;
    }
}
