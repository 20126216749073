.avatar {
    @apply
        rounded-full
        bg-gray-100
        text-sm
        font-semibold;

    -webkit-font-smoothing: initial;

    &.avatar-sm {
        @apply text-xs;
    }
}
