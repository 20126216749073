.select {
    @apply relative;
}

select.input {
    @apply appearance-none w-full;

    height: 56px;
}

.select-dropdown-arrow {
    @apply
        pointer-events-none
        absolute
        inset-y-0
        right-0
        flex
        items-center
        px-lg;

    svg {
        @apply fill-current h-md w-md;
    }
}
