input.is-search {
    @apply h-4xl w-full;

    @screen lg {
        width: 430px;
    }
}

input.is-search.has-icon-left {
    @apply pl-3xl;
}

input.is-search.has-icon-right {
    @apply pr-3xl;
}

@screen lg {
    input.input-no-label.is-search.has-icon-left ~ .input-icon {
        @apply ml-lg;
    }
}
