.btn {
    @apply
        inline-flex
        items-center
        justify-center
        select-none;

    &:disabled {
        @apply opacity-50 cursor-not-allowed;
    }

    &.is-ghost-link {
        @apply flex justify-start;
    }
}

.btn-sm {
    @apply h-2xl px-xl;
}

.btn-md {
    @apply h-3xl px-2xl;
}

.btn-lg {
    @apply px-2xl;

    height: 48px;
}

// Loader used in Form Loader
.btn.is-loading {
    @apply relative;

    transition: padding-right 0.1s ease-out;
    pointer-events: none;

    &::after {
        content: "";
        position: absolute;
        border-radius: 100%;
        right: 6px;
        top: 50%;
        width: 0;
        height: 0;
        animation: spin 0.6s infinite linear, grow 0.1s forwards ease-out;
    }
}

@keyframes spin {
    to {
        transform: rotate(359deg);
    }
}

@keyframes grow {
    to {
        width: 14px;
        height: 14px;
        margin-top: -8px;
        right: 13px;
    }
}

.btn.has-only-icon {
    @apply h-2xl w-2xl;

    &.btn-sm {
        @apply h-xl w-xl p-0;
    }

    &.btn-md {
        @apply h-3xl w-3xl p-0;
    }
}

.btn-link,
.btn-text {
    @apply text-left px-0;
}
