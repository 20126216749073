.hamburger-btn {
    @apply
        block
        w-xl
        h-xl
        pt-xs;
}

.hamburger-btn::before,
.hamburger-btn::after {
    @apply
        block
        h-xs
        transition-all
        duration-200
        ease-in-out;

    content: '';
}

.hamburger-btn::before {
    margin-bottom: 10px;
}

.hamburger-btn.is-open {
    &::before {
        transform: translateY(8px) rotate(45deg);
    }

    &::after {
        transform: translateY(-6px) rotate(-45deg);
    }
}
