.toggle {
    @apply
        relative
        h-2xl
        w-4xl
        pl-xs
        inline-flex
        items-center
        cursor-pointer
        transition-all
        duration-300
        ease-in-out;

    &:focus {
        @apply outline-none;
    }
}

.toggle-circle {
    @apply
        relative
        inline-block
        w-xl
        h-xl
        transform
        translate-x-0
        transition
        duration-300
        ease-in-out;
}

.toggle.is-small {
    @apply h-xl;

    width: 46px;
    padding-left: 2px;

    &.is-active {
        @apply pl-xs;
    }
}

.toggle.is-small .toggle-circle {
    height: 20px;
    width: 20px;
}

.toggle.is-active .toggle-circle {
    transform: translateX(calc(100% - 8px));
}
