.crud-form {
    @apply w-full;

    @screen md {
        @apply flex flex-col items-center;

        > * {
            @apply max-w-crud;
        }
    }
}
