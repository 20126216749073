.row {
    @apply flex flex-wrap -mx-sm;

    @screen md {
        @apply -mx-md;
    }

    @screen xl {
        @apply -mx-lg;
    }

    @screen xl {
        .card-half {
            @apply w-1/2 px-lg;
        }
    }
}

.column {
    @apply px-sm w-full;

    @screen md {
        @apply px-md;
    }

    @screen xl {
        @apply px-lg;
    }
}

.row-no-gutters {
    @apply flex flex-wrap;
}
