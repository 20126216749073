.table-list {
    @apply
        text-sm
        rounded
        bg-white;

    border-spacing: unset;

    th {
        @apply
            border-b
            font-semibold
            text-gray-900
            border-gray-100;
    }

    td {
        @apply
            border-b
            border-gray-100;
    }

    &.is-striped {
        tbody td {
            @apply border-0;
        }

        tbody tr:nth-child(odd) {
            @apply bg-gray-100;
        }
    }
}
